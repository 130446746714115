import SEO from '@cuidardigital/commons/components/Seo/seo'
import React from 'react'
import PagePatientCancelSms from '../../components/PagePatientCancelSms'

export default (props: any) => (
	<>
		<SEO />
		<PagePatientCancelSms {...props} />
	</>
)
