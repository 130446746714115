import queryString from 'query-string'
import React, { useState } from 'react'

import { Loader } from '@cuidardigital/commons/components/Loader'

import { ComponentWrapper, FormWrapper, Logo, WelcomeText } from './styles'

import { logout } from '@cuidardigital/commons/services/auth'
import logo from '../../assets/img/logo.png'

interface IObjectLiteral {
	[key: string]: any
}

interface IProps {
	location: IObjectLiteral
}

const PatientCancelSms: React.FC<IProps> = ({ location }) => {
	const [loading, setLoading] = useState(true)
	const [success, setSuccess] = useState(false)

	const getPatientInfo = (id: string) => {
		try {
			// TODO get infos do paciente
			// apiNewPatient(id)
			// 	.then((response: any) => {
			// 		if (response && response.status >= 200 && response.status <= 207) {
			// 			setSuccess(true)
			// 		}
			// 	})
			// 	.finally(() => {
			// 		setLoading(false)
			// 	})
		} catch (error) {
			setLoading(false)
		}
	}

	const qsValues = queryString.parse(location.search)
	if (qsValues && qsValues.id) {
		try {
			getPatientInfo(qsValues.id)
		} catch {
			logout()
		}
	} else {
		logout()
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>
			<ComponentWrapper>
				<FormWrapper>
					<Logo src={logo} />
					<WelcomeText
						fontSize='16px/32px'
						dangerouslySetInnerHTML={{
							__html:
								'Olá! <br/>' +
								(success
									? 'Tudo pronto! Você não receberá mais SMS da plataforma Saúde Digital'
									: 'Algum erro aconteceu e não conseguimos atualizar sua preferência por não receber mais SMSs da plataforma Saúde Digital. <br/>Tente novamente mais tarde.')
						}}
					/>
				</FormWrapper>
			</ComponentWrapper>
		</>
	)
}

export default PatientCancelSms
